import React from 'react'
import HeaderOther from '../../Components/HeaderOther/HeaderOther'
import { gallery1, gallery10, gallery11, gallery12, gallery13, gallery14, gallery15, gallery16, gallery17, gallery18, gallery19, gallery2, gallery20, gallery21, gallery22, gallery23, gallery24, gallery25, gallery26, gallery27, gallery3, gallery4, gallery5, gallery6, gallery7, gallery8, gallery9, services3, servicesBg, whyus, whyus2 } from '../../assets/images'

const PhotoGallery = () => {
  return (
    <>
    <HeaderOther/>
      <div>
    <div className='max-w-container w-[80%] mx-auto'>
    <p className='mt-[20px] text-[#C5C5C5] text-[14px]'><span className='text-[#DDB14C]'>Ana səhifə / Media</span> / Video</p>
    <p className='mt-[20px] text-[32px] font-semibold text-center'>Media</p>
 <div className='flex mb-[40px] justify-between mt-[40px]'>
 <div className='grid grid-cols-3 gap-5'>
<div>
  <img className='h-[245px]' src={gallery1} alt="Why Us"></img>
</div>
<div>
  <img className='h-[245px]' src={gallery2} alt="Why Us"></img>
</div>
<div>
  <img className='h-[245px]' src={gallery3} alt="Why Us"></img>
</div>
<div>
  <img className='h-[245px]' src={gallery4} alt="Why Us"></img>
</div>
<div>
  <img className='h-[245px]' src={gallery5} alt="Why Us"></img>
</div>
<div>
  <img className='h-[245px]' src={gallery6} alt="Why Us"></img>
</div>
<div>
  <img className='h-[245px]' src={gallery7} alt="Why Us"></img>
</div>
<div>
  <img className='h-[245px]' src={gallery8} alt="Why Us"></img>
</div>
<div>
  <img className='h-[245px]' src={gallery9} alt="Why Us"></img>
</div>
<div>
  <img className='h-[245px]' src={gallery10} alt="Why Us"></img>
</div>
<div>
  <img className='h-[245px]' src={gallery11} alt="Why Us"></img>
</div>
<div>
  <img className='h-[245px]' src={gallery12} alt="Why Us"></img>
</div>
<div>
  <img className='h-[245px]' src={gallery13} alt="Why Us"></img>
</div>
<div>
  <img className='h-[245px]' src={gallery14} alt="Why Us"></img>
</div>
<div>
  <img className='h-[245px]' src={gallery15} alt="Why Us"></img>
</div>
<div>
  <img className='h-[245px]' src={gallery16} alt="Why Us"></img>
</div>
<div>
  <img className='h-[245px]' src={gallery17} alt="Why Us"></img>
</div>
<div>
  <img className='h-[245px]' src={gallery18} alt="Why Us"></img>
</div>
<div>
  <img className='h-[245px]' src={gallery19} alt="Why Us"></img>
</div>
<div>
  <img className='h-[245px]' src={gallery20} alt="Why Us"></img>
</div>
<div>
  <img className='h-[245px]' src={gallery21} alt="Why Us"></img>
</div>
<div>
  <img className='h-[245px]' src={gallery22} alt="Why Us"></img>
</div>
<div>
  <img className='h-[245px]' src={gallery23} alt="Why Us"></img>
</div>
<div>
  <img className='h-[245px]' src={gallery24} alt="Why Us"></img>
</div>
<div>
  <img className='h-[245px]' src={gallery25} alt="Why Us"></img>
</div>
<div>
  <img className='h-[245px]' src={gallery26} alt="Why Us"></img>
</div>
<div>
  <img className='h-[245px]' src={gallery27} alt="Why Us"></img>
</div>

 </div>
 </div>
    </div>
  </div>
    </>
  )
}

export default PhotoGallery
