import React from 'react'
import HeaderOther from '../../Components/HeaderOther/HeaderOther'
import {gallery1, gallery10, gallery11, gallery12, gallery13, gallery14, gallery15, gallery16, gallery17, gallery18, gallery19, gallery2, gallery20, gallery21, gallery22, gallery23, gallery24, gallery25, gallery26, gallery27, gallery3, gallery4, gallery5, gallery6, gallery7, gallery8, gallery9, services3, servicesBg, whyus, whyus2 } from '../../assets/images'


const NewsDetail = () => {
  return (
    <>
    <HeaderOther/>
      <div>
    <div className='max-w-container w-[80%] mx-auto'>
    <p className='mt-[20px] text-[#C5C5C5] text-[14px]'><span className='text-[#DDB14C]'>Ana səhifə</span> / Xəbərlər</p>
    <p className='mt-[20px] text-[32px] font-semibold text-center'>Xəbərlər</p>
 <div className='flex mb-[40px] justify-between mt-[40px]'>
<p>Ətraflı</p>
 </div>
    </div>
  </div>
    </>
  )
}

export default NewsDetail
