import React, { useState, useRef, useEffect } from 'react';
import CountUp from 'react-countup';

const Awards = () => {
  const [startCounting, setStartCounting] = useState(false);
  const awardsRef = useRef(null); // Reference to the component

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setStartCounting(true); // Start counting when in view
        }
      },
      { threshold: 0.5 } // Trigger when 50% of the component is visible
    );

    if (awardsRef.current) {
      observer.observe(awardsRef.current);
    }

    return () => {
      if (awardsRef.current) {
        observer.unobserve(awardsRef.current); // Cleanup observer on unmount
      }
    };
  }, []);

  return (
    <div className='bg-[black] h-[200px]' ref={awardsRef}>
      <div className='w-[80%] mx-auto'>
        <div className='mx-auto flex justify-between h-[200px] relative'>
          <div className='flex flex-col justify-center items-center w-1/3 relative'>
            <p className='text-center text-[#DDB14C] text-[40px]'>
              {startCounting && <CountUp end={1200} duration={3} />} {/* CountUp for 1200 */}
            </p>
            <p className='text-center text-[white] text-[24px]'>Əməkdaş</p>
            {/* Short vertical line */}
            <span className="absolute right-0 top-1/2 transform -translate-y-1/2 h-[60%] w-[2px] bg-[#FFFFFF]" />
          </div>

          <div className='flex flex-col justify-center items-center w-1/3 relative'>
            <p className='text-center text-[#DDB14C] text-[40px]'>
              {startCounting && <CountUp end={16} duration={3} />} {/* CountUp for 16 */}
            </p>
            <p className='text-center text-[white] text-[24px]'>Komanda</p>
            {/* Short vertical line */}
            <span className="absolute right-0 top-1/2 transform -translate-y-1/2 h-[60%] w-[2px] bg-[#FFFFFF]" />
          </div>

          <div className='flex flex-col justify-center items-center w-1/3'>
            <p className='text-center text-[#DDB14C] text-[40px]'>
              {startCounting && <CountUp end={20} duration={3} />} {/* CountUp for 20 */}
            </p>
            <p className='text-center text-[white] text-[24px]'>Sifarişçi təşkilat</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Awards;
