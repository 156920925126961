import React from 'react'
import { discount1, discount2, discount3, hero1, hero2, heroBg } from '../../../assets/images';


const Hero = () => {
  return (
     <div className="w-[80%] mx-auto flex justify-between h-[633px] ">
      <div className='flex flex-col justify-center mx-auto text-center'>
      <h1 className='text-[50px] font-extrabold text-[white]'>SOS SECURİTY</h1>
      <h1 className='text-[50px] font-extrabold text-[white]'>Etibarlı mühafizənin təminatçısı!</h1>
        <p className='text-[20px] text-[white]'>Daxili İşlər Nazirliyinin tabeliyində, tam etibarlı və qanuni mühafizə xidmətləri təqdim edirik.</p>
       <div className='flex mx-auto mt-5'>
       <a href='/about'><button className='bg-[#DDB14C] mr-5 h-[40px] w-[148px] rounded-xl text-[white] mt-[10px]'>Daha çox</button></a>
       <a href='/contact'><button className='border-2 border-[#DDB14C] h-[40px] w-[148px] rounded-xl text-[white] mt-[10px]'>Əlaqə</button></a>
       </div>
      </div>
  </div>
  )
} 

export default Hero 