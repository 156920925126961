import React, { useState } from 'react';
import { logo } from '../../assets/images';

const Header = () => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [isSubMenuOpen2, setIsSubMenuOpen2] = useState(false);

  return (
    <> 
      <div className=' h-[80px] pt-5 text-[white]'>
        <div className='max-w-container mx-auto h-[45px] flex justify-between'>
          <div>
            <a href='/'><img alt='logo' height={59} width={94} src={logo}></img></a>
          </div>
          <div className='flex justify-center items-center'>
          <nav>
  <ul className="flex space-x-8 text-lg">
    <a href="/">
      <li className='text-[#DDB14C]'>Ana səhifə</li>
    </a>
    <li 
                  className='relative'
                  onMouseEnter={() => setIsSubMenuOpen2(true)}
                  onMouseLeave={() => setIsSubMenuOpen2(false)}
                >
                  <a href='/services'><li className='cursor-pointer'>Xidmətlər</li></a>
                  {isSubMenuOpen2 && (
                    <ul className='absolute left-0 bg-[white] text-[black] shadow-lg border border-gray-200 w-[200px]'>
                      <li><a href="/services/fiziki" className='block px-4 py-2 hover:bg-gray-100'>Fiziki mühafizə</a></li>
                      <li><a href="/services/video" className='block px-4 py-2 hover:bg-gray-100'>Video və dronla müşahidə</a></li>
                      <li><a href="/services/events" className='block px-4 py-2 hover:bg-gray-100'>Tədbirlərin mühafizəsi</a></li>
                      <li><a href="/services/tehlukesizlik" className='block px-4 py-2 hover:bg-gray-100'>Təhlükəsizlik sistemləri</a></li>
                    </ul>
                  )}
                </li>
    <a href="/about">
      <li>Haqqımızda</li>
    </a>
    <a href="/news">
      <li>Xəbərlər</li>
    </a>
    <li 
                  className='relative'
                  onMouseEnter={() => setIsSubMenuOpen(true)}
                  onMouseLeave={() => setIsSubMenuOpen(false)}
                >
                  <li className='cursor-pointer'>Media</li>
                  {isSubMenuOpen && (
                    <ul className='absolute left-0 bg-[white] text-[black] shadow-lg border border-gray-200'>
                      <li><a href="/media/photo-gallery" className='block px-4 py-2 hover:bg-gray-100'>Fotoqalereya</a></li>
                      <li><a href="/media/video" className='block px-4 py-2 hover:bg-gray-100'>Video</a></li>
                    </ul>
                  )}
                </li>
    <a href="/contact">
      <li>Əlaqə</li>
    </a>
  </ul>
</nav>

          </div>
        </div>
      </div>
    </>
  );
};

export default Header;




























