import React from 'react'
import { blogLeft, blogRightBottom, blogRightTop, leaf, productBg, servicesBg, video, whyus } from '../../assets/images';
import HeaderOther from '../../Components/HeaderOther/HeaderOther';

const ServicesVideo = () => {
  return (
   <>
   <HeaderOther/>
   <div className='w-[80%] mx-auto'>
   <p className='mt-[20px]'><span className='text-[#DDB14C]'>Ana səhifə / Xidmətlər</span> / Video və dronla müşahidə</p>
   <div className='flex justify-between mt-[40px] mb-[100px]'>
   <div className="flex flex-col gap-3 w-[320px]">
   <a href="/services/fiziki">
    <button className="h-[51px] bg-[#F1F1F1] rounded-md mt-[85px] w-full text-left pl-4 hover:bg-[#DDB14C] hover:text-[white] transition-all duration-300 ease-in-out">
    Fiziki mühafizə
    </button>
  </a>
  <a href="/services/video">
    <button disabled className="h-[51px] text-[white] bg-[#DDB14C] rounded-md w-full text-left pl-4">
      Video və dronla müşahidə
    </button>
  </a>
  <a href="/services/events">
    <button className="h-[51px] bg-[#F1F1F1] rounded-md w-full text-left pl-4 hover:bg-[#DDB14C] hover:text-[white] transition-all duration-300 ease-in-out">
      Tədbirlərin mühafizəsi
    </button>
  </a>
  <a href="/services/tehlukesizlik">
    <button className="h-[51px] bg-[#F1F1F1] rounded-md w-full text-left pl-4 hover:bg-[#DDB14C] hover:text-[white] transition-all duration-300 ease-in-out">
      Təhlükəsizlik sistemləri
    </button>
  </a>
</div>
    <div className='w-3/5'>
    <p className='mb-[45px] text-[32px] font-semibold'>Video və dronla müşahidə</p>
      <img src={video}></img>
      <p className='mt-5'>“Security Operation Services” MMC sifarişçi təşkilatlar qarşısındakı öhdəliklərinin müasir dövrün tələblərinə uyğunlaşdırmaqla, keyfiyyətli, dayanıqlı, operativ xidməti təmin etmək məqsədi ilə dövrü olaraq, yeni texnologiya və innovasiyaların imkanlarından istifadə etməklə həyata keçirir.  Elm və texnologiyanın yüksək sürətlə inkişaf etdiyi hazırkı dövrdə mühafizə sahəsində mümkün risklərin müəyyən edilməsi, onlara qarşı cavab tədbirlərinin görülməsi istiqamətində səmərəli fəaliyyətin təşkili ən vacib  məsələrdən hesab olunur. Sifarişçi məmuniyyətini nəzərə alaraq, fiziki mühafizə, tədbirlərin mühafizəsi,  video  müşahidə,  infrastruktur və obyektlərin mühafizəsində  yanğından mühafizə işinin təşkili, təhlükəsizlik və kamera sistemlərinin quraşdırılması, onlara texniki dəstəyin verilməsi də daxil olmaqla, müxtəlif istiqamətlər üzrə keyfiyyətli xidmət göstəririk</p>
    </div>
   </div>
   </div>
  </>
  )
}

export default ServicesVideo