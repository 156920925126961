import React, { useState } from 'react';
import { logo } from '../../assets/images';
import { useLocation } from 'react-router-dom';

const HeaderOther = () => {
  const location = useLocation();
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [isSubMenuOpen2, setIsSubMenuOpen2] = useState(false);

  // Function to check if the current link or its subpaths are active
  const isActiveLink = (path) => location.pathname.startsWith(path);

  return (
    <> 
      <div className='h-[80px] pt-5'>
        <div className='max-w-container mx-auto h-[45px] flex justify-between w-[80%] mx-auto'>
          <div>
            <a href='/'><img alt='logo' height={59} width={94} src={logo} /></a>
          </div> 
          <div className='flex justify-center items-center'>
            <nav>
              <ul className="flex space-x-8">
                <a href="/">
                  <li >Ana səhifə</li>
                </a>
                <li 
                  className='relative'
                  onMouseEnter={() => setIsSubMenuOpen2(true)}
                  onMouseLeave={() => setIsSubMenuOpen2(false)}
                >
                  <a href='/services'>
                    <li className={isActiveLink('/services') ? 'text-[#DDB14C] cursor-pointer' : 'cursor-pointer'}>Xidmətlər</li>
                  </a>
                  {isSubMenuOpen2 && (
                    <ul className='absolute left-0 bg-white shadow-lg border border-gray-200 w-[200px]'>
                      <li><a href="/services/fiziki" className='block px-4 py-2 hover:bg-gray-100'>Fiziki mühafizə</a></li>
                      <li><a href="/services/video" className='block px-4 py-2 hover:bg-gray-100'>Video və dronla müşahidə</a></li>
                      <li><a href="/services/events" className='block px-4 py-2 hover:bg-gray-100'>Tədbirlərin mühafizəsi</a></li>
                      <li><a href="/services/tehlukesizlik" className='block px-4 py-2 hover:bg-gray-100'>Təhlükəsizlik sistemləri</a></li>
                    </ul>
                  )}
                </li>
                <a href="/about">
                  <li className={isActiveLink('/about') ? 'text-[#DDB14C]' : ''}>Haqqımızda</li>
                </a>
                <a href="/news">
                  <li className={isActiveLink('/news') ? 'text-[#DDB14C]' : ''}>Xəbərlər</li>
                </a>
                <li 
                  className='relative'
                  onMouseEnter={() => setIsSubMenuOpen(true)}
                  onMouseLeave={() => setIsSubMenuOpen(false)}
                >
                  <li className={isActiveLink('/media') ? 'text-[#DDB14C] cursor-pointer' : 'cursor-pointer'}>Media</li>
                  {isSubMenuOpen && (
                    <ul className='absolute left-0 bg-white shadow-lg border border-gray-200'>
                      <li><a href="/media/photo-gallery" className='block px-4 py-2 hover:bg-gray-100'>Fotoqalereya</a></li>
                      <li><a href="/media/video" className='block px-4 py-2 hover:bg-gray-100'>Video</a></li>
                    </ul>
                  )}
                </li>
                <a href="/contact">
                  <li className={isActiveLink('/contact') ? 'text-[#DDB14C]' : ''}>Əlaqə</li>
                </a>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderOther;






