import React from 'react';
import { whyus } from '../../../assets/images';

const About = () => {

  return (
    <div className=''> 
    <div className='w-[80%] mx-auto'>
      <div className='  mt-[100px] mb-[100px]'>
      <p className='text-[32px] font-medium text-center mb-[50px]'>Haqqımızda</p>
       <div className='flex justify-between'>
       <div className='w-1/3'>
          <img className='h-[436px]' src={whyus} alt="Why Us"></img>
        </div>
        <div className='w-3/5 flex flex-col justify-center '>
          <p>
          "Security Operation Services" MMC Azərbaycan Respublikası Daxili İşlər Nazirliyinin 24 iyul 2020-ci il tarixli, 108 nömrəli Lisenziyası əsasında 2020-ci ilin noyabr ayının 1 dən "Qeyri-dövlət (özəl) mühafizə fəaliyyəti haqqında Azərbaycan Respublikasının Qanununa müvafiq olaraq mühafizə xidmətini həyata keçirən özəl mühafizə şirkətidir. "Security Operation Services" MMC Azərbaycan Respublikası Daxili İşlər Nazirliyinin 24 iyul 2020-ci il tarixli, 108 nömrəli Lisenziyası əsasında 2020-ci ilin noyabr ayının 1 dən "Qeyri-dövlət (özəl) mühafizə fəaliyyəti haqqında Azərbaycan Respublikasının Qanununa müvafiq olaraq mühafizə xidmətini həyata keçirən özəl mühafizə şirkətidir.
          </p>
         <a href='/about'> <p className='text-[#DDB14C] mt-3'>Ətraflı bax <i class="fa-solid fa-arrow-right"></i></p></a>
        </div>
       </div>
      </div>
    </div> 
  </div>
  );
};

export default About;
