import React from 'react'
import { blogBg } from '../../assets/images'
import PaginatedItems from './PaginatedItems'

const Blog = () => {
  return (
   <>
     <div style={{height:'230px', backgroundImage: `url(${blogBg})`, backgroundSize: 'cover', backgroundPosition: 'center', display: 'flex', justifyContent:'center', alignItems:'end' }}>
     <div className='mb-[44px] text-center'>
       <p className='text-[#6F9B0B] text-[40px] font-medium'>Bloq</p>
     </div>
   </div>
    <PaginatedItems itemsPerPage={4} />
    
    </>
  )
}

export default Blog