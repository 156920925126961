import React from 'react'
import HeaderOther from '../../Components/HeaderOther/HeaderOther'
import { whyus, whyus2 } from '../../assets/images'

const About = () => {
  return (
    <>
     <HeaderOther/>
     <div className='w-[90%] lg:w-[80%] mx-auto'>
     <p className='mt-[20px] mb-[30px] lg:mb-[60px] text-sm lg:text-base'><span className='text-[#DDB14C]'>Ana səhifə</span> / Haqqımızda</p>
     </div>
     <div className=''> 
      <div className='w-[90%] lg:w-[80%] mx-auto'>
        <div className='mb-[40px] lg:mb-[70px]'>
          <p className='text-[24px] lg:text-[32px] font-medium text-center mb-[30px] lg:mb-[50px]'>Haqqımızda</p>
          <div className='flex flex-col lg:flex-row justify-between'>
            <div className='lg:w-1/3 mb-[20px] lg:mb-0'>
              <img className='h-auto lg:h-[436px] w-full object-cover' src={whyus} alt="Why Us"></img>
            </div>
            <div className='lg:w-3/5 flex flex-col justify-center'>
              <p className='text-justify'>
                "Security Operation Services" MMC Azərbaycan Respublikası Daxili İşlər Nazirliyinin 24 iyul 2020-ci il tarixli, 108 nömrəli Lisenziyası əsasında 2020-ci ilin noyabr ayının 1 dən "Qeyri-dövlət (özəl) mühafizə fəaliyyəti haqqında Azərbaycan Respublikasının Qanununa müvafiq olaraq mühafizə xidmətini həyata keçirən özəl mühafizə şirkətidir.
              </p>
            </div>
          </div>
        </div>
      </div> 
    </div>
    <div className='text-[black] h-auto lg:h-[500px] mb-[40px] lg:mb-[70px]'>
      <div className='w-[90%] lg:w-[80%] mx-auto pt-5 lg:pt-10 pb-10'>
        <div className='text-center w-full lg:w-2/3 mx-auto'>
          <p className='text-[24px] lg:text-[32px]'>Bizi seçməyiniz üçün səbəblər</p> 
          <p className='text-[#A7A7A7] italic text-[16px] lg:text-[18px] mt-2'>Hədəfimiz – müasir dünyada qabaqcıl texnologiyanı tətbiq etməklə daim inkişaf etmək, Məqsədimiz – tərəfdaş məmnuniyyəti qazanmaqdır!</p>
        </div>
        <div className='flex flex-col lg:flex-row mt-10'>
          <div className='lg:w-1/3 mb-5 lg:mb-0'>
            <img className='h-auto lg:h-[276px] w-full object-cover' src={whyus2}></img>
          </div>
          <div className='lg:w-2/3'>
            <div className='p-5 lg:p-10'>
              <div className='pb-5'>
                <p className='text-[16px] lg:text-[18px] font-semibold'>Yerli və beynəlxalq təcrübə</p>
                <p className='text-[#525252] text-sm lg:text-base'>Əməkdaşlarını mütamadi olaraq mühafizə obyektlərinin xüsusiyyətlərinə uyğun xüsusi (beynəlxalq və yerli) əhəmiyyətli kurslara cəlb etməklə “Security Operation Services” MMC tərəfdaşlarına keyfiyyətli xidməti təqdim edir.</p>
              </div>
              <div>
                <p className='text-[16px] lg:text-[18px] font-semibold'>Müasir Texnologiya</p>
                <p className='text-[#525252] text-sm lg:text-base'>Müasir dünyada müstəsna yer tutan texnologiya və texnoloji avadanlıqların imkanlarını xidmətinizə inteqrasiya etməklə daimi yenilənməni təmin edir və peşəkar fəaliyyətimizi həyata keçiririk.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default About
