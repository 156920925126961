import React, { useState } from 'react';
import { logo } from '../../assets/images';

const AboveHeader = () => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [isSubMenuOpen2, setIsSubMenuOpen2] = useState(false);

  return (
    <> 
      <div className='h-[80px] pt-5 text-[white]'>
        <div className='max-w-container mx-auto h-[45px] flex justify-between'>
          <div className='flex'>
         <div className='flex mr-10'>
         <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.36464 4.43015L8.90548 5.39926C9.39357 6.27383 9.19763 7.42111 8.42891 8.18984C8.4289 8.18984 8.42891 8.18984 8.4289 8.18985C8.42879 8.18996 7.49656 9.1224 9.18707 10.8129C10.8769 12.5028 11.8093 11.5719 11.8101 11.5711C11.8102 11.5711 11.8102 11.5711 11.8102 11.571C12.5789 10.8024 13.7262 10.6064 14.6007 11.0945L15.5698 11.6354C16.8904 12.3724 17.0464 14.2244 15.8856 15.3852C15.1881 16.0827 14.3336 16.6254 13.3891 16.6612C11.799 16.7215 9.09854 16.3191 6.38973 13.6103C3.68092 10.9014 3.27849 8.20103 3.33877 6.61092C3.37458 5.66634 3.91732 4.81187 4.61482 4.11437C5.77561 2.95358 7.62762 3.10954 8.36464 4.43015Z" fill="#C5C5C5"/>
</svg>
<p>+994 70 000 00 00</p>
         </div>
<div className='flex'>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C7.58172 2 4 6.00258 4 10.5C4 14.9622 6.55332 19.8124 10.5371 21.6744C11.4657 22.1085 12.5343 22.1085 13.4629 21.6744C17.4467 19.8124 20 14.9622 20 10.5C20 6.00258 16.4183 2 12 2ZM12 12C13.1046 12 14 11.1046 14 10C14 8.89543 13.1046 8 12 8C10.8954 8 10 8.89543 10 10C10 11.1046 10.8954 12 12 12Z" fill="#C5C5C5"/>
</svg>
<p>Sabunçu Rayonu, Balaxanı qəsəbəsi, Ramiz Mirişli 102</p>
</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboveHeader;




























