

import React, { useEffect, useState } from "react";
import { FaWhatsapp } from "react-icons/fa6";
import { CiMail } from "react-icons/ci";
import { useTranslation } from "react-i18next";


const Contact = () => {

  const { t, i18n } = useTranslation(); 
  return (
    <>
    <div className="bg-[black] h-[590px] flex justify-center items-center">
    <div
        style={{
          backgroundImage: `url('./contact.png')`,
          backgroundSize: 'cover',   // Resmin alanı tamamen kaplaması için
          backgroundPosition: 'center', // Ortada hizalama
        }}
        className="bg-black h-[392px] flex flex-col justify-center items-center w-full"
      >
        <p className="ext-center text-white text-[32px] font-extrabold tracking-wide">EHTİYACLARINIZA UYĞUN SABİT TƏRƏFDAŞ</p>
        <p className="text-center text-white text-[40px] font-extrabold uppercase">+994 77 280 01 03</p>
      </div>
    </div>
    </>

  );
};

export default Contact;