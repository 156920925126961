import React from 'react';
import { partners1, partners2, partners3, partners4 } from '../../../assets/images';

const Partners = () => {
  return (
    <div className='h-[410px] flex justify-center items-center'>
      <div className='w-[80%] flex flex-col mx-auto justify-between h-[240px]'>
        <div className='text-[32px] text-center font-semibold'><p>Partnyorlar</p></div>
        <div className='h-[145px] flex justify-between items-center'>
          <img className='h-[66px] object-contain' src={partners1} alt="Partner 1" />
          <img className='h-[120px] object-contain' src={partners2} alt="Partner 2" />
          <img className='h-[90px] object-contain' src={partners3} alt="Partner 3" />
          <img className='h-[90px] object-contain' src={partners4} alt="Partner 4" />
        </div>
      </div> 
    </div>
  );
};

export default Partners;

