import React from 'react';
import { contact1, contactBg, whyus } from '../../assets/images';
import HeaderOther from '../../Components/HeaderOther/HeaderOther';

const Contact = () => {
  return (
    <>
      <HeaderOther />
      <div className="w-full">
        <div className="max-w-container w-[90%] lg:w-[80%] mx-auto h-auto mb-[100px]">
          {/* Breadcrumb */}
          <p className="mt-[20px] text-[#C5C5C5] text-[14px]">
            <span className="text-[#DDB14C]">Ana səhifə</span> / Əlaqə
          </p>
          {/* Title */}
          <p className="mt-[20px] text-[32px] font-semibold text-center">Əlaqə</p>

          {/* Main contact section */}
          <div className="flex flex-col lg:flex-row lg:justify-between mt-[40px] mb-[40px] space-y-10 lg:space-y-0 lg:space-x-4">
            {/* Contact Info */}
            <div className="w-full lg:w-[25%] flex flex-col space-y-8">
              {/* Contact Number */}
              <div className="flex items-start">
                <div className="rounded-full bg-[#DDB14C] h-[60px] w-[60px] mr-[10px]">
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.3471 17.3436C21.1472 14.5435 25.8724 14.7564 27.8221 18.2499L28.9038 20.1881C30.177 22.4695 29.6344 25.348 27.7706 27.2346C27.7458 27.2686 27.6142 27.4601 27.5978 27.7948C27.5769 28.222 27.7286 29.21 29.2593 30.7407C30.7895 32.2709 31.7774 32.423 32.2048 32.4022C32.5397 32.3859 32.7314 32.2543 32.7655 32.2294C34.6521 30.3656 37.5306 29.8231 39.8119 31.0963L41.7501 32.1779C45.2437 34.1276 45.4565 38.8529 42.6564 41.653C41.1587 43.1508 39.1671 44.4816 36.8268 44.5703C33.3586 44.7018 27.5999 43.8061 21.8969 38.1032C16.1939 32.4002 15.2983 26.6415 15.4297 23.1732C15.5185 20.8329 16.8493 18.8414 18.3471 17.3436ZM25.6391 19.4682C24.6407 17.6793 21.9579 17.2683 20.1148 19.1114C18.8226 20.4036 17.9825 21.83 17.9279 23.268C17.8183 26.1602 18.5324 31.2031 23.6646 36.3354C28.7969 41.4677 33.8399 42.1817 36.7321 42.0721C38.1701 42.0176 39.5964 41.1775 40.8887 39.8852C42.7317 38.0422 42.3207 35.3594 40.5318 34.361L38.5936 33.2793C37.3879 32.6064 35.6944 32.836 34.5055 34.0248L34.5051 34.0251C34.3884 34.1419 33.6453 34.8351 32.3262 34.8993C30.9759 34.965 29.3413 34.3582 27.4916 32.5085C25.6412 30.6581 25.0347 29.0231 25.1008 27.6726C25.1654 26.3534 25.8591 25.6107 25.9752 25.4946L25.9752 25.4946C27.1641 24.3057 27.3936 22.6121 26.7207 21.4065L25.6391 19.4682Z" fill="white"/>
</svg>

                </div>
                <div className="mt-[5px]">
                  <h3 className="text-lg font-semibold">Əlaqə nömrəsi</h3>
                  <p className="text-[#BCBCBC]">+994 77 280 01 03</p>
                </div>
              </div>
              {/* Email */}
              <div className="flex items-start">
                <div className="rounded-full bg-[#DDB14C] h-[60px] w-[60px] mr-[10px]">
                  
<svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.3471 17.3436C21.1472 14.5435 25.8724 14.7564 27.8221 18.2499L28.9038 20.1881C30.177 22.4695 29.6344 25.348 27.7706 27.2346C27.7458 27.2686 27.6142 27.4601 27.5978 27.7948C27.5769 28.222 27.7286 29.21 29.2593 30.7407C30.7895 32.2709 31.7774 32.423 32.2048 32.4022C32.5397 32.3859 32.7314 32.2543 32.7655 32.2294C34.6521 30.3656 37.5306 29.8231 39.8119 31.0963L41.7501 32.1779C45.2437 34.1276 45.4565 38.8529 42.6564 41.653C41.1587 43.1508 39.1671 44.4816 36.8268 44.5703C33.3586 44.7018 27.5999 43.8061 21.8969 38.1032C16.1939 32.4002 15.2983 26.6415 15.4297 23.1732C15.5185 20.8329 16.8493 18.8414 18.3471 17.3436ZM25.6391 19.4682C24.6407 17.6793 21.9579 17.2683 20.1148 19.1114C18.8226 20.4036 17.9825 21.83 17.9279 23.268C17.8183 26.1602 18.5324 31.2031 23.6646 36.3354C28.7969 41.4677 33.8399 42.1817 36.7321 42.0721C38.1701 42.0176 39.5964 41.1775 40.8887 39.8852C42.7317 38.0422 42.3207 35.3594 40.5318 34.361L38.5936 33.2793C37.3879 32.6064 35.6944 32.836 34.5055 34.0248L34.5051 34.0251C34.3884 34.1419 33.6453 34.8351 32.3262 34.8993C30.9759 34.965 29.3413 34.3582 27.4916 32.5085C25.6412 30.6581 25.0347 29.0231 25.1008 27.6726C25.1654 26.3534 25.8591 25.6107 25.9752 25.4946L25.9752 25.4946C27.1641 24.3057 27.3936 22.6121 26.7207 21.4065L25.6391 19.4682Z" fill="white"/>
</svg>
                </div>
                <div className="mt-[5px]">
                  <h3 className="text-lg font-semibold">E-poçta adresi</h3>
                  <p className="text-[#BCBCBC]">infosos.az</p>
                </div>
              </div>
              {/* Address */}
              <div className="flex items-start">
                <div className="rounded-full bg-[#DDB14C] h-[60px] w-[60px] mr-[10px]">
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M59.9958 30.001C59.9958 45.1529 48.7647 57.6793 34.1746 59.712C32.8104 59.9012 31.4147 60 29.9979 60C28.3625 60 26.7566 59.8697 25.1927 59.6174C10.9095 57.3177 0 44.9322 0 30.001C0 13.4324 13.4319 0 30 0C46.5681 0 60 13.4324 60 30.001H59.9958Z" fill="#DDB14C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M19.0625 27.6791C19.0625 21.5553 23.9438 16.5625 30 16.5625C36.0562 16.5625 40.9375 21.5553 40.9375 27.6791C40.9375 30.6355 40.095 33.8099 38.6056 36.5523C37.118 39.2914 34.9445 41.6715 32.2256 42.9424C30.8132 43.6025 29.1868 43.6025 27.7744 42.9424C25.0555 41.6715 22.882 39.2914 21.3944 36.5523C19.905 33.8099 19.0625 30.6355 19.0625 27.6791ZM30 18.4375C25.0105 18.4375 20.9375 22.5594 20.9375 27.6791C20.9375 30.3005 21.6908 33.1693 23.0421 35.6575C24.3953 38.1491 26.3075 40.187 28.5683 41.2438C29.4776 41.6687 30.5224 41.6687 31.4317 41.2438C33.6925 40.187 35.6047 38.1491 36.9579 35.6575C38.3092 33.1693 39.0625 30.3005 39.0625 27.6791C39.0625 22.5594 34.9895 18.4375 30 18.4375ZM30 24.6875C28.4467 24.6875 27.1875 25.9467 27.1875 27.5C27.1875 29.0533 28.4467 30.3125 30 30.3125C31.5533 30.3125 32.8125 29.0533 32.8125 27.5C32.8125 25.9467 31.5533 24.6875 30 24.6875ZM25.3125 27.5C25.3125 24.9112 27.4112 22.8125 30 22.8125C32.5888 22.8125 34.6875 24.9112 34.6875 27.5C34.6875 30.0888 32.5888 32.1875 30 32.1875C27.4112 32.1875 25.3125 30.0888 25.3125 27.5Z" fill="white"/>
</svg>
                </div>
                <div className="mt-[5px]">
                  <h3 className="text-lg font-semibold">Ünvan</h3>
                  <p className="text-[#BCBCBC]">Sabunçu rayonu, Balaxanı qəsəbəsi, Ramiz Mirişli 102</p>
                </div>
              </div>
            </div>

            {/* Contact Form */}
            <div className="w-full lg:w-[60%]">
              <form className="space-y-6">
                <div className="grid grid-cols-1 gap-6">
                  <div>
                    <label
                      htmlFor="company"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Ad, soyad
                    </label>
                    <input
                      type="text"
                      id="company"
                      className="bg-[#F1F1F1] mt-2 block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                      placeholder="Ad, soyad"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Əlaqə nömrəsi
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="bg-[#F1F1F1] mt-2 block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                      placeholder="Əlaqə nömrəsi"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="quantity"
                      className="block text-sm font-medium text-gray-700"
                    >
                      E-mail
                    </label>
                    <input
                      type="number"
                      id="quantity"
                      className="bg-[#F1F1F1] mt-2 block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                      placeholder="E-mail"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="message"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Mesajınız
                  </label>
                  <textarea
                    id="message"
                    rows="4"
                    className="bg-[#F1F1F1] mt-2 block w-full px-4 py-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                    placeholder="Mesajınız"
                  ></textarea>
                </div>

                <div className="flex justify-start">
                  <button
                    type="submit"
                    className="bg-[#DDB14C] px-8 py-3 text-white rounded-md shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                  >
                    Göndər
                  </button>
                </div>
              </form>
            </div>
          </div>

          {/* Map Section */}
          <div className="h-[445px] w-full">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12142.468279236868!2d49.9200272!3d40.461611649999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4030898dd6144c29%3A0x6fab276612ee2bb3!2sBalakhani%2C%20Bak%C4%B1!5e0!3m2!1saz!2saz!4v1726741128914!5m2!1saz!2saz"
              width="100%"
              height="445"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;




















































































